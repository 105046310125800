import React from 'react'
import { Formik , Form } from 'formik'
import * as yup from 'yup'
//import usersResource from '../../resources/users'

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.string().optional,
  title_at_firm: yup.string().required('Firm title_at_firm is required'),
})

const PublicInfo = () => {
  return(
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        title_at_firm: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          // const result = await usersResource.updateCurrent({
          //   data: {
          //     user: {
          //       email: values.email,
          //       first_name: values.firstName,
          //       last_name: values.lastName,
          //       phone: values.phone,
          //       title_at_firm: values.title_at_firm
          //     }
          //   }
          // })
        } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            setFieldError('general', error.response.data.error)
          } else {
            console.error(error)
          }
        } finally {
          setSubmitting(false)
        }
      }}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        }) => (
        <Form
          onSubmit={handleSubmit}
          style={{width: '250px'}}
          data-testid="form">

          <div className="help" data-testid="error">
            {errors.general ? errors.general : ''}
          </div>

          <input
            name="email"
            data-testid="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Email"
          />

          <div className="help">
            {touched.email && errors.email ? errors.email : ''}
          </div>

          <input
            name="firstName"
            type="text"
            data-testid="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="First name"
          />

          <div className="help">
            {touched.firstName && errors.firstName ? errors.firstName : ''}
          </div>

          <input
            name="lastName"
            data-testid="lastName"
            type="text"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Last name"
          />

          <div className="help">
            {touched.lastName && errors.lastName ? errors.lastName : ''}
          </div>

          <input
            name="phone"
            data-testid="phone"
            type="text"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Phone number"
          />

          <div className="help">
            {touched.phone && errors.phone ? errors.phone : ''}
          </div>

          <input
            name="title_at_firm"
            data-testid="title_at_firm"
            type="text"
            value={values.title_at_firm}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Firm title_at_firm"
          />

          <div className="help">
            {touched.title_at_firm && errors.title_at_firm ? errors.title_at_firm : ''}
          </div>

          <input
            type="submit"
            value="Update"
            disabled={isSubmitting}
            style={{marginTop: '8px'}}
          />
        </Form>
      )}
    />
  )
}

export default PublicInfo;
