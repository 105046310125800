import React, { useContext } from 'react'
//import { SessionContext } from 'contexts/session_context'
import SignOutButton from 'components/users/sign_out'

const Home = ({ history }) => {
  const sessionContext = useContext(SessionContext)

  return <>
    <h1>Per The Work Edit</h1>
    {/* { sessionContext.loading && <em>Loading...</em> }
    { !sessionContext.loading && sessionContext.user && <p>
      Logged in as {sessionContext.user.full_name}
      <SignOutButton />
    </p> } */}
    <strong>Welcome team!</strong> This app is a placeholder for now. We'll update it continuously.
    <p>
      Temporary links:
      <ul>
        <li><a href="/admin">Admin panel</a></li>
      </ul>
    </p>
  </>
}

export default Home
