import React, { useContext } from 'react'
//import { SessionContext } from 'contexts/session_context'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
//import usersResource from '../../../resources/users'
import * as routes from '../../../constants/routes'

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNumber: yup.number().notRequired(),
  title_at_firm: yup.string().notRequired(),
  firmName: yup.string().notRequired(),
  linkedin_url: yup.string().notRequired(),
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords do not match').required('Password Confirmation is required'),
})

const SignUp = ({ history }) => {
  //const sessionContext = useContext(SessionContext)

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        title_at_firm: '',
        firmName: '',
        linkedin_url: '',
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          // const result = await usersResource.create({
          //   data: {
          //     user: {
          //       email: values.email,
          //       first_name: values.firstName,
          //       last_name: values.lastName,
          //       phone_number: values.phoneNumber,
          //       title_at_firm: values.title_at_firm,
          //       firm_name: values.firmName,
          //       linkedin_url: values.linkedin_url,
          //       password: values.password,
          //       password_confirmation: values.passwordConfirmation
          //     }
          //   }
          // })
          const result = {}
          //sessionContext.setUser(result.data)
          if(result?.data?.status === 'pending')
          {
            history.push(routes.WELCOME_PAGE)
          }
          else
          {
            history.push(routes.ROOT)
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            setFieldError('general', error.response.data.error)
          } else {
            console.error(error)
          }
        } finally {
          setSubmitting(false)
        }
      }}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        }) => (
        <Form
          onSubmit={handleSubmit}
          style={{width: '250px'}}
          data-testid="form">

          <div className="help" data-testid="error">
            {errors.general ? errors.general : ''}
          </div>

          <input
            name="email"
            data-testid="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Email"
          />

          <div className="help">
            {touched.email && errors.email ? errors.email : ''}
          </div>

          <input
            name="firstName"
            type="text"
            data-testid="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="First name"
          />

          <div className="help">
            {touched.firstName && errors.firstName ? errors.firstName : ''}
          </div>

          <input
            name="lastName"
            data-testid="lastName"
            type="text"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Last name"
          />

          <div className="help">
            {touched.lastName && errors.lastName ? errors.lastName : ''}
          </div>

          <input
            name="phoneNumber"
            data-testid="phoneNumber"
            type="text"
            pattern="[0-9]*"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Phone Number"
          />

          <div className="help">
            {touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
          </div>

          <input
            name="linkedin_url"
            data-testid="linkedin_url"
            type="text"
            value={values.linkedin_url}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="linkedin_url"
          />

          <div className="help">
            {touched.linkedin_url && errors.linkedin_url ? errors.linkedin_url : ''}
          </div>

          <input
            name="title_at_firm"
            data-testid="title_at_firm"
            type="text"
            value={values.title_at_firm}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="title_at_firm"
          />

          <div className="help">
            {touched.title_at_firm && errors.title_at_firm ? errors.title_at_firm : ''}
          </div>

          <input
            name="firm"
            data-testid="firm"
            type="text"
            value={values.firmName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Firm Name"
          />

          <div className="help">
            {touched.firmName && errors.firmName ? errors.firmName : ''}
          </div>

          <input
            name="password"
            data-testid="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Password"
          />

          <div className="help">
            {touched.password && errors.password ? errors.password : ''}
          </div>

          <input
            name="passwordConfirmation"
            data-testid="passwordConfirmation"
            type="password"
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Password Confirmation"
          />

          <div className="help">
            {touched.passwordConfirmation && errors.passwordConfirmation ? errors.passwordConfirmation : ''}
          </div>

          <input
            type="submit"
            value="Sign up"
            disabled={isSubmitting}
            style={{marginTop: '8px'}}
          />
        </Form>
      )}
    />
  )
}

export default SignUp
